import React, { useEffect, useRef, useState } from 'react';
//import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
// import '../assets/css/owl.css'; 
import '../assets/css/animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/index.css';
import vd from '../assets/images/img/project3.MP4';

const MainBanner = () => {
  const videoRef = useRef(null);
  const [showText, setShowText] = useState(false);
  const fraction = 0.5; // Visibility threshold
  let isPlaying = false; // To track video play state

  const checkScroll = () => {
    const video = videoRef.current;
    if (!video) return;

    const rect = video.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const videoHeight = rect.height;
    const videoWidth = rect.width;

    // Check visibility in the viewport
    const visibleX = Math.max(0, Math.min(videoWidth, windowWidth - rect.left, rect.right));
    const visibleY = Math.max(0, Math.min(videoHeight, windowHeight - rect.top, rect.bottom));

    const visible = (visibleX * visibleY) / (videoWidth * videoHeight);

    if (visible > fraction && !isPlaying) {
      video.play().then(() => {
        isPlaying = true;
        setShowText(true); // Show text when video starts playing

        // Hide text after 3 seconds
        setTimeout(() => {
          setShowText(false);
        }, 5000);
      }).catch(error => {
        console.error('Failed to play the video:', error);
      });
    } else if (visible <= fraction && isPlaying) {
      video.pause();
      isPlaying = false;
    }
  };

  useEffect(() => {
    // Initialize AOS animations
    AOS.init();

    // Initial check
    checkScroll();

    // Add event listeners
    window.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  return (
    <div className="main-banner"  id="header">
      <div >
        <h6
          data-aos="fade-up"
          data-aos-duration="2500"
          
        >
          Industrial Design & Animation
        </h6>
        <div className="centered-hr"></div>
        <h1
          data-aos="fade-right"
          data-aos-duration="2500"
        >
          Future of Interaction: Merging Physical and Digital Worlds<br />Through AR/VR Innovation
        </h1>
      </div>
      <div className="video-container">
      {showText && (
        <div
        className="overlay-text"
        style={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Moves the element to the center
          color: 'white',
          fontSize: 'min(5vw, 100px)', // Responsive text size
          fontWeight: 'bold',
          borderRadius: '5px',
          textAlign: 'center', // Centers the text
          width: '100%', // Ensures the text spans across the container
          zIndex: 2, // Ensures the text is above the video
          
        }}
      >
        Virtual Expo
      </div>
    )}
    <video
      ref={videoRef}
      controls
      loop
      muted
      style={{
        zIndex: 1, // Ensure video is below the text
        height: 'auto' 
      }}
    >
      <source src={vd} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div> 
    </div>
  );
};

export default MainBanner;
