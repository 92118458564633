import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './contact.css';
import Footer from './Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    interest: '',
    service: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    interest: '',
    service: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  const handleNameInput = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\u0B80-\u0BFFa-zA-Z.\s]/g, '');
    setFormData({ ...formData, name: value });
  };

  const handleMobileNumberInput = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '').slice(0, 10); // Ensure only digits and limit to 10 digits
    setFormData({ ...formData, mobileNumber: value });

    // Validate the number while typing
    if (value === '0000000000') {
      setFormErrors({
        ...formErrors,
        mobileNumber: 'Mobile number cannot be all zeroes.',
      });
    } else if (!/^[6-9]\d{9}$/.test(value)) {
      setFormErrors({
        ...formErrors,
        mobileNumber: 'Please enter a valid mobile number.',
      });
    } else {
      setFormErrors({
        ...formErrors,
        mobileNumber: '', // Clear error if valid
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validation for name
    if (!formData.name) {
      errors.name = 'Name is required';
      isValid = false;
    } else if (formData.name.length < 3 || formData.name.length > 20) {
      errors.name = 'Name must be between 3 and 20 characters';
      isValid = false;
    }

    // Validation for email
    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (formData.email.length < 4 || formData.email.length > 60) {
      errors.email = 'Email length must be between 4 and 60 characters';
      isValid = false;
    } else if (!isNaN(formData.email.charAt(0))) {
      errors.email = 'Please enter a valid email address';
      isValid = false;
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'Please enter a valid email address';
      isValid = false;
    }

    // Validation for mobile number
    if (!formData.mobileNumber) {
      errors.mobileNumber = 'Mobile number is required';
      isValid = false;
    } else if (formData.mobileNumber === '0000000000') {
      errors.mobileNumber = 'Mobile number cannot be all zeroes.';
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = 'Please enter a valid mobile number.';
      isValid = false;
    }

    // Validation for area of interest
    if (!formData.interest) {
      errors.interest = 'Area of Interest is required';
      isValid = false;
    } else if (!/^[a-zA-Z0-9\s\/\.\-]{3,40}$/.test(formData.interest)) {
      errors.interest =
        'Area of Interest should be alphanumeric with special characters (/, ., -) allowed, between 3 and 40 characters';
      isValid = false;
    }

    // Validation for required services
    if (!formData.service) {
      errors.service = 'Required Services is required';
      isValid = false;
    } else if (!/^[a-zA-Z0-9\s\/\.\-]{3,40}$/.test(formData.service)) {
      errors.service =
        'Required Services should be alphanumeric with special characters (/, ., -) allowed, between 3 and 40 characters';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData(e.target);

      formData.append('access_key', '5bb89199-7c30-4fa7-b216-54b6345e0b8e');

      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      const res = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: json,
      }).then((res) => res.json());

      if (res.success) {
        console.log('Success', res);

        // Update isSubmitted to show success dialog
        setIsSubmitted(true);

        // Clear form data and errors after successful submission
        setFormData({
          name: '',
          email: '',
          mobileNumber: '',
          interest: '',
          service: '',
        });
        setFormErrors({
          name: '',
          email: '',
          mobileNumber: '',
          interest: '',
          service: '',
        });
      } else {
        console.error('Error', res);
      }
    }
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]{4,60}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(String(email).toLowerCase()) && !email.startsWith('.');
  };

  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row justify-content-center">
          {/* Centering the form */}
          <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-12 mt-5">
            <div className="section-heading">
              <br></br>
              <br></br>
              <br></br>
              <h2>
                Level Up Your Reality - <em> Start Now!!</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="2000" className="col-lg-12 mb-3">
            <form id="contact" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Your Name"
                      autoComplete="on"
                      value={formData.name}
                      onChange={handleNameInput}
                    />
                    <div id="name-error" className="error-message">
                      {formErrors.name}
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="email">Email ID</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email Id"
                      autoComplete="on"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <div id="email-error" className="error-message">
                      {formErrors.email}
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <input
                      type="tel"
                      name="mobileNumber"
                      id="mobileNumber"
                      placeholder="Enter Your Mobile Number"
                      autoComplete="on"
                      value={formData.mobileNumber}
                      onChange={handleMobileNumberInput}
                    />
                    <div id="mobileNumber-error" className="error-message">
                      {formErrors.mobileNumber}
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="interest">Area Of Interest</label>
                    <input
                      type="text"
                      name="interest"
                      id="interest"
                      placeholder="Enter Your Area of Interest"
                      autoComplete="on"
                      value={formData.interest}
                      onChange={handleChange}
                    />
                    <div id="interest-error" className="error-message">
                      {formErrors.interest}
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <label htmlFor="service">Required Services</label>
                    <input
                      type="text"
                      name="service"
                      id="service"
                      placeholder="Enter Required Services"
                      autoComplete="on"
                      value={formData.service}
                      onChange={handleChange}
                    />
                    <div id="service-error" className="error-message">
                      {formErrors.service}
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-12 text-center">
                  {/* Centering the button */}
                  <button type="submit" id="form-submit" className="orange-button">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Success dialog */}
        {isSubmitted && (
          <div className="success-dialog">
            <div className="dialog-content">
              <h4>Form Submitted Successfully!</h4>
              <button onClick={() => setIsSubmitted(false)} className="close-button">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Contact;