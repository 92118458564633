import React from 'react';
import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
// import '../assets/css/owl.css'; 
// import '../assets/css/animate.css';
import '../assets/css/index.css';
import { FaFacebookSquare,  FaLinkedin } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import email from '../assets/images/img/email.png';
import instra from '../assets/images/img/instagram.png';
import wt from '../assets/images/img/whatsapp.png';

//import jk from '../';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container-fluid p-5 pb-5" style={{ backgroundColor: '#282828', color: 'white' }}>
        <div className="container1 footer pt-1">
          <div className="row1">
            <div className="col-lg-4 offset-lg-0 offset-md-3 col-md-9 ps-lg-4 ps-3">
              <h5 className="mb-3">Address</h5>
              <p>INDUSTRIAL DESIGN & ANIMATIONS</p>
              <p>Karunaa Conclave,</p>
              <p>3rd Floor, 5th Main Road,</p>
              <p>Shanthi Colony, Anna Nagar, Chennai,</p>
              <p>Tamil Nadu - 600040, INDIA</p>
              <p>
                Email: <a href="mailto:info@idacreations.com">info@idacreations.com</a>
              </p>
              <div className="footer-icons pt-2">
                
                <a href="https://twitter.com/IDA_design2023" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faXTwitter} size="1x" className="icon-with-bg" />
                </a>
                <a href="https://www.linkedin.com/company/industrial-design-animations/mycompany/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} size="1x" className="icon-bg"/>
               </a>
              </div>
            </div>


            <div className="col-lg-4 offset-lg-0 offset-md-3 col-md-9">
              <h5 className="hidden mb-3">Locate us on Map</h5>
              <div className="responsive-iframe-container">
    <iframe
        src="http://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2212.814528104551!2d80.20722491526668!3d13.082677813247336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52641f73827315%3A0xc60a47141c034265!2sSyncfusion%20Software%20Pvt%20Ltd%2C%20Karuna%20Conclave%20Block!5e0!3m2!1sen!2sin!4v1708534373678!5m2!1sen!2sin"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
</div>
            </div>
           
            {/* Quick Links Section */}
            
            <div className="col-lg-3 offset-lg-0 offset-md-3 col-md-9">
               
              <h5 className="mb-3">Quick Links</h5>
              <ul className="list-unstyled">
              <li><a href="/#header" style={{ color: 'white', textDecoration: 'none' }}>Home</a></li>
                <li><a href="/about" style={{ color: 'white', textDecoration: 'none' }}>About Us</a></li>
                <li><a href="/details" style={{ color: 'white', textDecoration: 'none' }}>Services</a></li>
                <li><a href="/#footer" style={{ color: 'white', textDecoration: 'none' }}>Contact</a></li>
                <li><a href="/contact" style={{ color: 'white', textDecoration: 'none' }}>Join Us</a></li>
              </ul>
            </div>

            <div className="col-lg-4 offset-lg-0 offset-md-3 col-md-9 ps-lg-4 ps-3">
            <h5 className="mb-3" >Contact Us</h5>
            <div className="">
              <div className="row footer-enquiry text-center pt-4">
              <div className="col-lg-4 hidden">
                
                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@idacreations.com" target="_blank" rel="noopener noreferrer">
                  <img style={{ width: '40px' }} src={email} alt="Send Enquiry" />
                </a>
                <p className="pt-2 text-center">
                  <small>Send Enquiry</small>
                </p>
              </div>

                <div className="col-lg-4 hidden">
                  <a href="https://www.instagram.com/ida_studio_94?igsh=MTU5eTVuMHl1ZHZn&utm_source=qr" target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '40px' }} src={instra} alt="Instagram Profile" />
                  </a>
                  <p className="pt-2 text-center">
                    <small>Insta Profile</small>
                  </p>
                </div>

                <div className="col-lg-4 hidden">
                  <a href=" https://wa.me/919042392115" target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '40px' }} src={wt} alt="WhatsApp" />
                  </a>
                  <p className="pt-2 text-center">
                    <small>WhatsApp</small>
                  </p>
                </div>
              </div>

              <div className="row main-button pt-5 hidden-1">
                <div className="col-lg-4 offset-lg-8 col-12 pb-5 text-center">
                  <a href="/contact" style={{ fontSize: '12px' }}>
                    Join now
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center mt-3">
            <p>
              Copyright © 2024 <a href="#">IDACreations</a>.
              &nbsp;&nbsp;
              Designed by{' '}
              <a title="" rel="sponsored" href="http://idacreations.com/" target="_blank">
                IDACreations
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;