import React from 'react';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/my new/fund_benefit_sketch-model-2d-3d_3.webp';
import image2 from '../assets/images/my new/mot.png';
import image3 from '../assets/images/my new/VFX.png';
import image4 from '../assets/images/my new/AR.png';
import image5 from '../assets/images/my new/VP.png';
import Footer from './Footer';


function ThreeDmodel() {
  return (
    <>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studio</h6>
              <h2>3D Modelling &amp; Rendering</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="col-lg-12">
              <div className="section-heading">
                <br></br><br></br>
                <h2 className="text-center">Visualize your imagination in <em>3D.</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
              <img 
                src={image1} 
                alt="3D Modeling" 
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }} 
              />
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Crafting Dreams in 3D.</h3>
              <p className="mt-3">
                In the realm of 3D modeling, artists embark on a journey of creativity and precision, sculpting digital clay into intricate forms that transcend the boundaries of imagination. With each vertex and polygon meticulously placed, they weave together a tapestry of digital artistry that forms the foundation of virtual worlds and characters. Rendering, as the final act of this creative process, harnesses the power of computational wizardry to breathe life into these digital constructs, infusing them with light, texture, and atmosphere. Through the interplay of modeling and rendering, artists wield the tools of technology to craft immersive experiences that blur the lines between reality and imagination, inviting audiences into mesmerizing realms limited only by the bounds of creativity.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="section-heading" style={{marginTop:'5px'}}>
              <h2 className="text-center">What We <em>offer</em></h2>
              <br />
              <div className="line-dec"></div>
              <h5>
                Step into virtual reality with IDA's 3D modeling and rendering services. Our talented artists sculpt detailed 3D models and bring them to life with stunning visualizations, delivering photorealistic results that captivate and inspire viewers, elevating your projects to new heights of realism and sophistication.
              </h5>
              <p></p>
            </div>

            
              <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image2} alt="3D Model Creation"
                      style={{ 
                        borderRadius: '20px', 
                        height: 'auto', 
                        width: '100%', 
                        maxWidth: '700px', 
                        objectFit: 'cover' 
                      }} 
                       />
                      <h4 className="text-center">3D Model Creation</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Turn ideas into 3D visuals with our service. Bring your concepts to life effortlessly.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image3} alt="3D Rendering" 
                      style={{ 
                        borderRadius: '20px', 
                        height: 'auto', 
                        width: '100%', 
                        maxWidth: '700px', 
                        objectFit: 'cover' 
                      }} />
                      <h4 className="text-center">3D Rendering</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Experience lifelike designs with our 3D Rendering. Impress your audience with stunning visuals.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image4} alt="Asset Creation" 
                      style={{ 
                        borderRadius: '20px', 
                        height: 'auto', 
                        width: '100%', 
                        maxWidth: '700px', 
                        objectFit: 'cover' 
                      }} />
                      <h4 className="text-center">Asset Creation</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Enhance your projects with our Asset Creation. Get high-quality assets tailored to your needs.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image5} alt="Digital Twin" 
                      style={{ 
                        borderRadius: '20px', 
                        height: 'auto', 
                        width: '100%', 
                        maxWidth: '700px', 
                        objectFit: 'cover' 
                      }}/>
                      <h4 className="text-center">Digital Twin</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Explore virtual replicas with Digital Twin technology. Gain insights like never before.</h6>
                    </div>
                  </div>
                </div>
              </div>
            
            <div className="container1">
              <div style={{ fontWeight: '900' }} className="btn"><Link to = "/contact">Join Now</Link></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ThreeDmodel;
 